import { datadogLogs } from '@datadog/browser-logs'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import Button from '../components/form/Button'
import ManageDiaperFundModal from '../components/ManageDiaperFundModal'
import Context from '../utils/Context'
import { gaEvent } from '../utils/GA'
import { DiaperBankSearchResults } from './api/diaperBanks/search'

type SearchForm = { searchTerm?: string }
export default function Home() {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SearchForm>()
  const [results, setResults] = useState<DiaperBankSearchResults['results']>()
  const context = useContext(Context)

  const onFormSubmit = async (values: SearchForm) => {
    const response = await getSearchResults(values)
    setResults(response.data?.results)
  }

  const selectedSearchTerm = watch('searchTerm')

  const { mutateAsync: getSearchResults, isLoading } = useMutation(({ searchTerm }: SearchForm) => {
    let params: string[] = []
    if (searchTerm) params.push(`term=${encodeURIComponent(searchTerm)}`)
    gaEvent({
      action: 'df_search',
      params: {
        event_label: searchTerm,
      },
    })
    datadogLogs.logger.info(`Searching for ${searchTerm}`, { searchTerm })
    return context.apiClient.get<DiaperBankSearchResults>(`diaperBanks/search?${params.join('&')}`)
  })

  return (
    <div className='p-4 pt-8'>
      <h2 className='text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate text-center'>Hello Bello Diaper Fund</h2>

      <div className='form-fieldset mt-6'>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div>
            <label htmlFor='searchTerm' className='text-md'>
              Search All
            </label>

            <div className='mt-1 flex shadow-sm   '>
              <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                <input
                  type='text'
                  id='searchTerm'
                  className='block w-full p-3 border-wisteria-light focus:outline-none border-l border-t border-b focus-within:border-wisteria '
                  placeholder='Search'
                  autoFocus
                  autoComplete='off'
                  {...register('searchTerm')}
                />
                {!!selectedSearchTerm && (
                  <button
                    type='reset'
                    className='text-gray-300 z-20 top-3 absolute right-1 h-6 w-6 outline-none focus:outline-none '
                    onClick={() => {
                      reset({ searchTerm: undefined })
                      setResults(undefined)
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </div>
              <button
                type='submit'
                className='-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-wisteria text-sm font-medium text-white bg-wisteria focus:outline-none border-l-0'
              >
                {isLoading ? (
                  <svg className='animate-spin  h-5 w-5 inline' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                    <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                ) : (
                  <FontAwesomeIcon icon={faSearch} className='h-5 w-5 text-white' aria-hidden='true' />
                )}
                <span className='sr-only'>Search</span>
              </button>
            </div>

            {!!results?.length && (
              <div>
                <ul className='divide-y divide-gray-300'>
                  {results.map((result) => (
                    <Link href={`/bank/${result.slug}`} key={result.slug}>
                      <li className='py-4 flex' key={result.slug}>
                        {result.profileImageUrl && <img className='h-10 w-10 rounded-full' src={result.profileImageUrl} alt={result.babyName} />}

                        <div className='ml-3 cursor-pointer'>
                          <p className='text-sm font-medium text-gray-900'>{result.babyName}</p>
                          <p className='text-sm text-gray-600 font-light'>
                            <span className='text-gray-700'>{result.recipientName}</span>
                            {!!result.organizerName && (
                              <>
                                <br />
                                <span className='text-xs'>Organized by {result.organizerName}</span>
                              </>
                            )}
                          </p>
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </form>
      </div>

      <div className='with-horizontal-line text-sm mt-6'>OR</div>
      {/* <div className='text-sm border-gray-400 border-t mt-8 text-gray-400 relative'>
        <div className='absolute left-1/2 -top-2 px-2 opacity-100 z-5 bg-opacity-100'>OR</div>
      </div> */}
      <div className='text-center mt-6'>
        <Link href='/bank/new'>
          <a className='btn btn-primary w-3/4'>Create a diaper fund</a>
        </Link>
      </div>
      <div className='text-center mt-6'>
        <ManageDiaperFundModal>
          {(openModal) => (
            <Button type='button' onClick={openModal} outline color='primary' className='w-3/4'>
              Manage Account
            </Button>
          )}
        </ManageDiaperFundModal>
      </div>
    </div>
  )
}
